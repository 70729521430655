.header{
    background: rgb(19,19,113);
    background: radial-gradient(circle, rgba(19,19,113,1) 0%, rgba(11,8,62,1) 100%);
    font-family: 'Righteous', cursive;
    color: White;
    height: 4rem;
    display: flex;
    justify-content:left;
    padding-left: 3.5rem;
    padding-top: 1rem;
    font-size: 2.2rem;
}

.categories{
    font-size: 1rem;
    padding-left: 3rem;
    padding-top: 1rem;
    font-family: 'Sen', sans-serif;

}

#category{
    padding-left: 1rem;
}


@media(max-width:480px) {
    .categories{
        padding-left: 2rem;
    }

    .header{
        font-size: 1.8rem;
        padding-left: 2.5rem;

    }
}

