.movie {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.movie__detail {
    align-items: center;
    width: 75%;
    display: flex;
    bottom: 225px;
}

.movie__detailLeft {
    margin-right: 30px;
}

.movie__poster {
    width:300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;}
.movie__detailRight {
    color: white;
    display: flex;
    flex-direction: column;
    height: 450px;
    justify-content:space-between ;
    font-family: 'Public Sans', sans-serif;

}

.movie__detailRightTop  div{
    margin-bottom: .5rem;
}

.movie__name {
    font-weight: 600;
    font-size: 3rem;
    font-family: 'Poltawski Nowy', serif;

}

.movie__voteCount {
    margin-left: 1rem;
}

.movie__genres {
    margin: 1.25rem 0;
}

.movie__genre {
    padding: .4rem;
    border: 1px solid white;
    border-radius: 20px;
    margin-right: 1rem;
}


.movie__detailRightBottom {
    flex: .9;
}

.synopsisText {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
    display: flex;
    position: relative;
    align-items: center;
}
.synopsisText  div:last-of-type {
    margin-left: auto;
}

.movie__links {
    margin-top: 4rem;
    display: flex;
    justify-content: space-evenly;
    width: 75%;
}

.movie__heading {
    font-size: 2.2rem;

}

.movie__Button {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 2rem;
    border-radius: 20px;
    cursor: pointer;
    width: 150px;
    color: black;
    font-weight: bold;
    font-family: 'Public Sans', sans-serif;

}

.movie__Button:hover{
    scale: 1.1;
    transition: 100ms;

}

.movie__homeButton {
    background-color:rgb(0, 98, 255);
    color: white;
    
}

.movie__imdbButton {
    background-color:#f3ce13;
}

.newTab {
    margin-left: 1.4rem;
}

@media(max-width:480px) {
    .movie__poster{
        width: 200px;
        margin-bottom: 3rem;
    }

    .movie__detail {
        display: flex;
        flex-direction: column;
        max-width: 320px;
    }
    .movie__detailRight{
        height: max-content;
    }

    .movie__releaseDate{
        padding-bottom: .7rem;
    }

    .movie__detailRightTop{
        padding-bottom: 1.6rem;

    }

    .movie__Button {
        text-align: center;
        justify-content: center;
        padding: 0.8rem 2rem;
        border-radius: 10px;
        cursor: pointer;
        width: 60px;
        color: black;
        font-weight: bold;
    }
    
}