.footer{
    margin-top: 2rem;
    background: rgb(19,19,113);
    background: radial-gradient(circle, rgba(19,19,113,1) 0%, rgba(11,8,62,1) 100%);    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
}

.footer__heading{
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    font-family: 'Rubik', sans-serif;

}

.footer__heading a{
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
}

@media(max-width:480px) {
    html{
        font-size: 70%;
    }
    
}